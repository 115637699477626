<template>
    <div class="calculator" v-if="cars.length">
        <div class="car">
            <img class="body" :src="selectedCar.car">
            <template v-for="window in selectedCar.windows">
                <img
                    class="window"
                    :key="window.url"
                    :src="window.url"
                    :style="style(window)"
                    v-if="window.selected && window.url" />
            </template>
            <template v-if="selectedCar.lights.length">
                <template v-for="light in selectedCar.lights">
                    <img
                        class="light"
                        :key="light.url"
                        :src="light.url"
                        :style="style(light)"
                        v-if="light.selected && light.url" />
                </template>
            </template>
        </div>
        <div class="config">
            <select v-model="selectedType">
                <option v-for="type in cars" :key="type.name" :value="type">
                    {{ type.type }}
                </option>
            </select>
            <h2>Windows:</h2>
            <label class="option" v-for="(window, position) in selectedCar.windows" :key="`window-${position}`" :for="`window-${position}`">
                <input type="checkbox" :checked="window.selected" v-model="window.selected" :id="`window-${position}`" />
                <span>{{ window.name }} ({{ window.price }} EUR)</span>
            </label>
            <h2>Lights:</h2>
            <label class="option" v-for="(light, position) in selectedCar.lights" :key="`light-${position}`" :for="`light-${position}`">
                <input type="checkbox" :checked="light.selected" v-model="light.selected" :id="`light-${position}`" />
                <span>{{ light.name }} ({{ light.price }} EUR)</span>
            </label>
            <h2>Other options:</h2>
            <label class="option" v-for="(other, position) in selectedCar.other" :key="`other-${position}`" :for="`other-${position}`">
                <input type="checkbox" :checked="other.selected" v-model="other.selected" :id="`other-${position}`" />
                <span>{{ other.name }} ({{ other.price }} EUR)</span>
            </label>
        </div>
        <div class="price">
            <h2>Total price:</h2>
            <span>{{ totalPrice }} EUR</span>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

interface Offset {
    x: number;
    y: number;
}

interface Window {
    name: string;
    url: string;
    offset: Offset;
    width: number;
    price: number;
    selected: boolean;
}

interface Light {
    name: string;
    price: number;
    selected: boolean;
}

interface Other {
    name: string;
    price: number;
    selected: boolean;
}

interface Car {
    type: string;
    car: string;
    "windows": Window[];
    "lights": Light[];
    "other": Other[];
}

@Options({})
export default class Calculator extends Vue {
    msg!: string;

    selectedType: Car | null = null;
    cars: Car[] = [];

    created() {
        const configUrl = '/config.json'; // 'http://calc.hp-s.be/config.json';

        fetch(configUrl)
            .then(res => res.json())
            .then((out) => {
                this.cars = out;
                this.selectedType = Object.assign({}, this.cars[0]);
            })
            .catch(err => { throw err });
    }

    get selectedCar() {
        if (this.selectedType) {
            return this.selectedType;
        } else if (this.cars[0]) {
            return this.cars[0];
        }

        return {
            windows: [],
            lights: [],
            other: [],
        };
    }

    get totalPrice() {
        if (this.selectedCar) {
            const windowsPrice = this.selectedCar.windows.filter((window) => window.selected).reduce((a, b) => a + b.price, 0);
            const lightsPrice = this.selectedCar.lights.filter((light) => light.selected).reduce((a, b) => a + b.price, 0);
            const otherPrice = this.selectedCar.other.filter((other) => other.selected).reduce((a, b) => a + b.price, 0);

            return windowsPrice + lightsPrice + otherPrice;
        }

        return 0;
    }

    style(window: any) {
        return {
            'margin-left': `${window.offset.x}%`,
            'margin-top': `${window.offset.y}%`,
            'width': `${window.width}%`,
        };
    }
}
</script>

<style scoped lang="scss">
.calculator {
    @apply bg-gray-50 mx-auto w-full;

    @screen md {
        width: 700px;
    }

    .car {
        @apply w-full relative;

        .body {
            @apply w-full;
        }

        .window {
            @apply absolute top-0 left-0 opacity-60;
        }

        .light {
            @apply absolute top-0 left-0 opacity-40;
        }
    }

    .config {
        @apply p-8 pb-4;

        h2 {
            @apply text-xl;
        }

        .option {
            @apply inline-block mr-4;
        }
    }

    .price {
        @apply p-8 pt-0;

        h2 {
            @apply text-xl;
        }

        .option {
            @apply inline-block mr-4;
        }
    }
}
</style>
