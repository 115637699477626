<template>
  <div class="home">
    <Calculator msg="Welcome to Your Vue.js + TypeScript App"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Calculator from '@/components/Calculator.vue'; // @ is an alias to /src

@Options({
  components: {
      Calculator,
  },
})
export default class Home extends Vue {}
</script>
