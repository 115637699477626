
import { Options, Vue } from 'vue-class-component';

interface Offset {
    x: number;
    y: number;
}

interface Window {
    name: string;
    url: string;
    offset: Offset;
    width: number;
    price: number;
    selected: boolean;
}

interface Light {
    name: string;
    price: number;
    selected: boolean;
}

interface Other {
    name: string;
    price: number;
    selected: boolean;
}

interface Car {
    type: string;
    car: string;
    "windows": Window[];
    "lights": Light[];
    "other": Other[];
}

@Options({})
export default class Calculator extends Vue {
    msg!: string;

    selectedType: Car | null = null;
    cars: Car[] = [];

    created() {
        const configUrl = '/config.json'; // 'http://calc.hp-s.be/config.json';

        fetch(configUrl)
            .then(res => res.json())
            .then((out) => {
                this.cars = out;
                this.selectedType = Object.assign({}, this.cars[0]);
            })
            .catch(err => { throw err });
    }

    get selectedCar() {
        if (this.selectedType) {
            return this.selectedType;
        } else if (this.cars[0]) {
            return this.cars[0];
        }

        return {
            windows: [],
            lights: [],
            other: [],
        };
    }

    get totalPrice() {
        if (this.selectedCar) {
            const windowsPrice = this.selectedCar.windows.filter((window) => window.selected).reduce((a, b) => a + b.price, 0);
            const lightsPrice = this.selectedCar.lights.filter((light) => light.selected).reduce((a, b) => a + b.price, 0);
            const otherPrice = this.selectedCar.other.filter((other) => other.selected).reduce((a, b) => a + b.price, 0);

            return windowsPrice + lightsPrice + otherPrice;
        }

        return 0;
    }

    style(window: any) {
        return {
            'margin-left': `${window.offset.x}%`,
            'margin-top': `${window.offset.y}%`,
            'width': `${window.width}%`,
        };
    }
}
